import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { SurveyUtils } from '../helpers/survey-util';
import { BillingModel } from '../model/billing/billing.model';
import { ApiService } from './api.service';
import { BillingService } from './billing.service';

@Injectable({
  providedIn: 'root'
})
export class NPSSurveyService {

  isSurveyCompleted: boolean = false;
  public billing: BillingModel = null;
  public ignoreCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private isVisibleSubject = new BehaviorSubject<boolean>(false);
  modalVisibility = this.isVisibleSubject.asObservable();

  constructor(private apiService: ApiService, private billingService: BillingService) {
    this.billingService.subject.pipe(takeUntilDestroyed()).subscribe(billing => {
      if (billing) {
        this.billing = billing;
      }
    });
  }

  checkSurveyStatus(): void {
    if (this.billing && this.billing?.isOnTrial === true) {
      return;
    } else {
      this.apiService.surveyStatus().subscribe((response) => {
        if (response.data) { // If ignored/completed the survey
          this.isSurveyCompleted = response.data.feedbackType !== 'Ignore';
          if (!this.isSurveyCompleted) {
            const surveyIgnoreCount = response.data.ignoreCount;
            this.ignoreCount$.next(surveyIgnoreCount);
            const lastIgnoreDate = new Date(new Date(response.data.lastIgnoreDate).setHours(0, 0, 0, 0));
            const nextSurveyDate = SurveyUtils.calculateNextSurveyShowDate(lastIgnoreDate, surveyIgnoreCount);
            const today = new Date();
            if (today >= nextSurveyDate) {
              this.checkUrlAndShow();
            }
          }
        } else {  // Very first time login
          this.checkUrlAndShow();
        }
      });
    }
  }

  public checkUrlAndShow(): void {
    const currentUrl = window.location.pathname;
    if (!SurveyUtils.isPathBlacklisted(currentUrl)) {
      setTimeout(() => {
        this.show();
      }, 60000); // 30 seconds
    }
  }

  show(): void {
    this.isVisibleSubject && this.isVisibleSubject.next(true);
  }

  hide(): void {
    this.isVisibleSubject && this.isVisibleSubject.next(false);
  }
}
